import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Box, Button, Select as LOOXRSelect, Label, TextInput } from '@looxr/components';
import { Collections } from '@looxr/constants';
import { useTranslation } from '@looxr/utils';
import { REACT_SELECT_STYLES, REACT_SELECT_THEME, getLeakFields } from '../constants';
import useFormOptions from '../hooks/useFormOptions';
import { FirebaseService } from '../services';

function LeakSummaryEdit({ onSave, boxConfig }) {
  const tn = useTranslation();
  const formOptions = useFormOptions();

  // general summary box data
  const [name, setName] = useState(boxConfig.name);
  const [type, setType] = useState(boxConfig.type);
  const [customer, setCustomer] = useState(null);

  // signal or leak field options
  const [boxOptions, setBoxOptions] = useState([
    null, // 1
    null, // 2
    null, // 3
    null, // 4
    null, // 5
    null // 6
  ]);

  const [valid, setValid] = useState(false);

  // Effect to keep valid state up to date
  useEffect(() => {
    const load = async () => {
      const availableLeakFields = getLeakFields();
      const customerDoc = await boxConfig.customer.get();

      const selectedOptions = boxConfig.fields.map((selected) => {
        if (selected !== null) {
          const selectedConfig = availableLeakFields[selected];
          return {
            label: selectedConfig.label,
            value: selectedConfig.name
          };
        }

        return null;
      });

      setCustomer(customerDoc.data());
      setBoxOptions(selectedOptions);
    };

    load();
  }, [boxConfig]);

  // Effect to keep valid state up to date
  useEffect(() => {
    const hasOneOption = boxOptions.filter((option) => option !== null).length > 0;

    if (name && name.length <= 40 && hasOneOption) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [name, boxOptions]);

  const updateBoxOptions = (index, option) => {
    const update = [...boxOptions];
    // third party select needs whole option object to map
    update[index] = option;

    setBoxOptions(update);
  };

  const isOptionDisabled = (index, selectOption) => {
    const disabled =
      boxOptions.filter((option) => {
        if (option && option.value === selectOption.value) {
          return option;
        }

        return null;
      }).length > 0;

    return disabled;
  };

  const saveSummary = async () => {
    if (valid) {
      try {
        const update = { ...boxConfig };
        update.name = name;

        update.fields = boxOptions.map((option) => (option !== null ? option.value : null));

        const result = await FirebaseService.upsertDoc(
          boxConfig.id,
          update,
          Collections.summaryBox
        );

        if (result) {
          onSave();
        } else {
          // @TODO show error
        }
      } catch (e) {
        console.log(e);
        console.log('Error editing summary box');
      }
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="left"
        justify="flex-start"
        paddingX={5}
        paddingBottom={5}
        direction="column"
        minHeight="380px"
      >
        <Box>
          <Label htmlFor="name">
            {tn('landing.summaryboxform.name')}{' '}
            <small>
              ({tn('landing.summaryboxform.maxNameCharacters', { characters: name.length })})
            </small>
            {/* Name <small>({name.length} of max. 40 Characters)</small> */}
          </Label>
          <TextInput
            id="name"
            name="name"
            placeholder={tn('landing.summaryboxform.namePlaceholder')} //  "Bitte Name eingeben"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
        </Box>
        <Box marginTop={4}>
          <Label htmlFor="type">
            {tn('landing.summaryboxform.type')}
            {/* Typ */}
          </Label>
          <LOOXRSelect
            disabled={true}
            id="type"
            name="type"
            placeholder={tn('general.choose')} // "Bitte wählen"
            onChange={(e) => setType(e.value)}
            value={type || ''}
            options={formOptions.boxTypes}
            size="sm"
          />
        </Box>
        <Box marginTop={4}>
          <Label htmlFor="location">
            {tn('landing.summaryboxform.location')}
            {/* Standort */}
          </Label>
          <TextInput disabled={true} id="location" name="location" value={customer?.name1} />
        </Box>

        <Box marginTop={4}>
          <Label htmlFor="location">
            {tn('landing.summaryboxform.displayValues')}
            {/* Display Werte */}
          </Label>
          <Box display="flex" wrap="wrap" direction="row" justify="space-between">
            {[0, 1, 2, 3, 4, 5].map((i) => (
              <Box display="flex" width="30%" key={i} marginBottom={4}>
                <Select
                  menuPosition="fixed"
                  styles={REACT_SELECT_STYLES}
                  theme={REACT_SELECT_THEME}
                  value={boxOptions[i]}
                  onChange={(o) => updateBoxOptions(i, o)}
                  options={formOptions.leakFields}
                  isClearable={true}
                  noOptionsMessage={tn('general.noResult')} // "Kein Ergebnis"
                  placeholder={tn('general.choose')} // "Bitte wählen"
                  isOptionDisabled={(o) => isOptionDisabled(i, o)}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box marginTop={4}>
        <Button
          onClick={saveSummary}
          text={tn('general.save')} // "Speichern"
          disabled={!valid}
        />
      </Box>
    </>
  );
}

LeakSummaryEdit.propTypes = {
  boxConfig: PropTypes.any.isRequired,
  onSave: PropTypes.func
};

LeakSummaryEdit.defaultProps = {
  onSave: () => {}
};

export default LeakSummaryEdit;
