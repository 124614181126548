import { ApiService } from '../services';

function useLoadPressureSummary() {
  const getPressureSignalValues = async (boxConfig) => {
    try {
      const result = await ApiService.getSignalValues(boxConfig);

      if (result !== false) {
        return transformSignalValues(boxConfig, result);
      }

      return false;
    } catch (error) {
      console.log('Error getting leak values for summary box config');
      console.log(error);
      return false;
    }
  };

  const transformSignalValues = (config, signalValues) => {
    const mapped = [];
    const configuredSignals = config.signals;

    /*
      decimalPlaces: 2
      id: 118010001
      label: "Temperatur"
      name: "analog_og_temp1"
      scale: 0
      type: "FLOAT"
      unit: ""
      value: "-9,99"
    */

    if (signalValues && signalValues.length > 0) {
      for (let i = 0; i < configuredSignals.length; i += 1) {
        const signalID = configuredSignals[i];
        const signalValue = signalValues.find((signal) => `${signal.id}` === `${signalID}`);

        if (signalValue) {
          const numberValue =
            signalValue.type === 'FLOAT'
              ? parseFloat(signalValue.value)
              : parseInt(signalValue.value, 10);

          // @TODO ask if we need to apply scale here
          mapped[i] = {
            name: signalValue.name,
            value: numberValue,
            label: signalValue.label,
            unit: signalValue.unit,
            digits: signalValue.decimalPlaces
          };
        } else {
          mapped[i] = {
            name: `empty-${i}`,
            value: null,
            label: null,
            unit: null,
            digits: null
          };
        }
      }

      return mapped;
    }
    return false;
  };

  return {
    getPressureSignalValues,
    transformSignalValues
  };
}

export default useLoadPressureSummary;
