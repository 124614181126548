import React from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { useHistory } from 'react-router-dom';
import { Banner, Box, TeaserBox, Text } from '@looxr/components';
import { useTranslation } from '@looxr/utils';
import SummaryDummyBox from '../../components/DummyBox';
import {
  INTRO_STYLES,
  INTRO_SUMMARY_LEAK,
  INTRO_SUMMARY_PRESSURE,
  getIntroSteps
} from '../../constants';
import { AppStateService, FirebaseService } from '../../services';

function Intro() {
  const tn = useTranslation();
  const history = useHistory();

  const handleJoyrideCallback = async ({ status }) => {
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      const update = { ...AppStateService.user };
      const { id } = update;

      delete update.id;
      delete update.ref;

      update.hasFinishedLandingIntro = true;

      await FirebaseService.updateProfileData(id, update);

      history.push('/dashboard');
    }
  };

  return (
    <>
      <Box marginBottom={4}>
        <Banner />
        <Box display="flex" justify="center" margin={10}>
          <Box display="flex" width="100%" maxWidth={1280}>
            <Box width="100%">
              <TeaserBox>
                <Text bold color="black" size="sm">
                  {tn('landing.dashboard.welcome')}
                  {/* Willkommen bei LOOXR */}
                </Text>
                <Text semibold size="sm" color="grey">
                  {tn('landing.intro.username')}
                  {/* Max Mustermann */}
                </Text>
              </TeaserBox>

              <Box display="flex" wrap="wrap" justify="space-between" marginTop={6}>
                <SummaryDummyBox id="intro-summary-leak" data={INTRO_SUMMARY_LEAK} />
                <SummaryDummyBox id="intro-summary-pressure" data={INTRO_SUMMARY_PRESSURE} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* @TODO: mobile scroll */}
      <Joyride
        run={true}
        disableOverlayClose={true}
        showProgress={true}
        showSkipButton={true}
        continuous={true}
        callback={(data) => handleJoyrideCallback(data)}
        steps={getIntroSteps()}
        disableScrolling={false}
        styles={INTRO_STYLES}
        locale={{
          back: tn('general.back'), // 'Zurück',
          close: tn('general.close'), // 'Schließen',
          last: tn('general.end'), // 'Beenden',
          next: tn('general.continue'), // 'Weiter',
          skip: tn('general.skip') // 'Überspringen'
        }}
      />
    </>
  );
}

export default Intro;
