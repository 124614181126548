import { AUTH_API_URL, LOGIN_PORTAL_URL } from '@looxr/constants';

const AuthService = {
  /**
   * Check session status
   * @return {bool}
   */
  status: async () => {
    const url = `${AUTH_API_URL}/saml/status`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      cache: 'no-store',
      pragma: 'no-cache'
    });
    return response.ok;
  },

  /**
   * Get session user
   * @return {object}
   */
  getUser: async () => {
    const url = `${AUTH_API_URL}/saml/user`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      cache: 'no-store',
      pragma: 'no-cache'
    });

    if (response.ok) {
      return response.json();
    }

    return null;
  },

  /**
   * Redirect to login url
   * @return {object}
   */
  login: async () => {
    const url = `${AUTH_API_URL}/saml/login?returnUrl=${LOGIN_PORTAL_URL}`;
    window.location.href = url;
    return true;
  },

  /**
   * Redirect to login url
   * @return {object}
   */
  logout: async () => {
    const url = `${AUTH_API_URL}/saml/logout`;
    window.location.href = url;
    return true;
  }
};

export default AuthService;
