import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Banner, Box, LoadingBox, TeaserBox, Text } from '@looxr/components';
import { useTranslation } from '@looxr/utils';
import { SummaryBox } from '../../components';
import SummaryDummyBox from '../../components/DummyBox';
import SummaryCreateModal from '../../components/SummaryCreateModal';
import { INTRO_SUMMARY_LEAK, INTRO_SUMMARY_PRESSURE } from '../../constants';
import useLoadSummaryBoxes from '../../hooks/useLoadSummaryBoxes';
import { AppStateService } from '../../services';

const StyledContentWrapper = styled(Box)`
  margin: 1rem;

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    margin: 2.5rem;
  }
`;

function Dashboard() {
  const tn = useTranslation();
  const { getSummaryBoxesForUserQuery } = useLoadSummaryBoxes();

  const [loading, setLoading] = useState(true);
  const [summaryBoxes, setSummaryBoxes] = useState([]);
  const [showSummaryCreate, setShowSummaryCreate] = useState(false);

  // Effect to load summary boxes of user and keep them up to date
  useEffect(() => {
    const updateSummaryBoxes = (snapshot) => {
      setLoading(true);
      const docs = [];
      snapshot.forEach((doc) => {
        if (doc && doc.exists) {
          const docData = doc.data();
          docs.push({
            id: doc.id,
            ref: doc.ref,
            ...docData
          });
        }
      });

      setSummaryBoxes(docs);
      setLoading(false);
    };

    const unsub = getSummaryBoxesForUserQuery().onSnapshot(updateSummaryBoxes);

    return () => {
      unsub();
    };
  }, []);

  const onPressureMarketingBoxClick = () => {
    window.location.href = 'https://www.looxr.de/live-testen';
  };

  return (
    <Box marginBottom={4}>
      <Banner />
      <StyledContentWrapper display="flex" justify="center">
        <Box display="flex" width="100%" maxWidth={1280}>
          <Box width="100%">
            <TeaserBox>
              <Text bold color="black" size="sm">
                {tn('landing.dashboard.welcome')}
                {/* Willkommen bei LOOXR */}
              </Text>
              <Text semibold size="sm" color="grey">
                {AppStateService.user.firstname} {AppStateService.user.lastname}
              </Text>
            </TeaserBox>

            <LoadingBox loading={loading} renderChildren={!loading} minHeight={350}>
              <Box display="flex" wrap="wrap" justify="space-between" marginTop={6}>
                {summaryBoxes.map((box) => (
                  <SummaryBox key={box.id} boxConfig={box} />
                ))}

                {/* show marketing leak box: only fits since leak boxes are the only type for now */}
                {summaryBoxes.length === 0 && (
                  <SummaryDummyBox
                    id="intro-summary-leak"
                    data={INTRO_SUMMARY_LEAK}
                    label={tn('landing.dashboard.marketing.create')}
                    onClick={() => setShowSummaryCreate(true)}
                  />
                )}

                {/* always show pressure marketing box */}
                <SummaryDummyBox
                  id="intro-summary-pressure"
                  data={INTRO_SUMMARY_PRESSURE}
                  label={tn('landing.dashboard.marketing.test')}
                  onClick={onPressureMarketingBoxClick}
                />
              </Box>
            </LoadingBox>
          </Box>
        </Box>
      </StyledContentWrapper>

      {showSummaryCreate && (
        <SummaryCreateModal
          isVisible={showSummaryCreate}
          onClose={() => setShowSummaryCreate(false)}
          blockScroll={false}
        />
      )}
    </Box>
  );
}

export default Dashboard;
