import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { faLinkedin as LinkedInIcon, faXing as XingIcon } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Footer, FooterLink } from '@looxr/components';
import { useLanguage, useTranslation } from '@looxr/utils';
import { Header } from './components';
import { Dashboard, Intro, NoMatch } from './pages';
import { AppStateService } from './services';

export default function Routes() {
  const language = useLanguage();
  const tn = useTranslation();

  const getDefaultComponent = (props) => {
    const currentUser = AppStateService.user;
    if (currentUser && currentUser.hasFinishedLandingIntro) {
      return <Dashboard currentLocale={language} {...props} />;
    }
    return <Intro currentLocale={language} {...props} />;
  };

  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/" exact render={getDefaultComponent} />
        <Route
          path="/intro"
          exact
          render={(props) => <Intro currentLocale={language} {...props} />}
        />
        <Route
          path="/dashboard"
          exact
          render={(props) => <Dashboard currentLocale={language} {...props} />}
        />
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
      <Footer sticky>
        <Box display="flex" alignItems="center" justify="flex-start">
          <FooterLink linkTo="https://www.looxr.de/impressum">
            {tn('general.footer.imprint')}
            {/* Impressum */}
          </FooterLink>
          <FooterLink linkTo="https://www.looxr.de/j/privacy">
            {tn('general.footer.privacy')}
            {/* Datenschutz */}
          </FooterLink>
          <FooterLink linkTo="https://www.looxr.de/agb/">
            {tn('general.footer.agb')}
            {/* AGB */}
          </FooterLink>
        </Box>
        <Box display="flex" alignItems="center" justify="flex-end">
          <FooterLink linkTo="https://www.linkedin.com/company/looxr">
            <FontAwesomeIcon icon={LinkedInIcon} size="lg" />
          </FooterLink>
          <FooterLink linkTo="https://www.xing.com/pages/looxrgmbh">
            <FontAwesomeIcon icon={XingIcon} size="lg" />
          </FooterLink>
        </Box>
      </Footer>
    </Router>
  );
}
