import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { Box, NumberText, Text } from '@looxr/components';
import { BOX_TYPES } from '../constants';

const StyledContent = styled(Box)`
  width: 50%;

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    width: 33%;
  }
`;

function SummaryContentBlock({ type, label, value, unit, digits }) {
  return (
    <StyledContent marginY={2}>
      {label && (
        <Text size="sm" display="block" bold align="center">
          {label}
        </Text>
      )}

      {value !== '' && value !== null && (
        <NumberText
          size="base"
          color={type === BOX_TYPES.leak ? 'purple' : 'blue'}
          semibold
          display="block"
          align="center"
          number={value}
          digits={digits}
          suffix={
            <Text size="xs" inline color={type === BOX_TYPES.leak ? 'purple' : 'blue'}>
              {unit}
            </Text>
          }
        />
      )}
    </StyledContent>
  );
}

SummaryContentBlock.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string,
  digits: PropTypes.number
};

SummaryContentBlock.defaultProps = {
  label: '',
  value: '',
  unit: '',
  digits: 2
};

export default SummaryContentBlock;
