export const INTRO_SUMMARY_LEAK = {
  id: 1,
  type: 'LEAK',
  title: 'Leckage Portal',
  data: [
    {
      name: 'leaks_total',
      unit_de: '',
      unit_en: '',
      label_de: 'Leckagen',
      label_en: 'Leaks',
      value: 231
    },

    {
      name: 'energy_costs',
      unit_de: '€/Jahr',
      unit_en: '€/Year',
      label_de: 'Energiekosten',
      label_en: 'Energy costs',
      value: 30302
    },
    {
      name: 'energy_consumption',
      unit_de: 'kWh/Jahr',
      unit_en: 'kWh/Year',
      label_de: 'Energieverbrauch',
      label_en: 'power consumption',
      value: 214354.71
    },

    {
      name: 'repaired_leaks_total',
      unit_de: '',
      unit_en: '',
      label_de: 'Leckagen (repariert)',
      label_en: 'Leaks (repaired)',
      value: 187
    },
    {
      name: 'energy_cost_saving',
      unit_de: '€/Jahr',
      unit_en: '€/Year',
      label_de: 'Einsparung',
      label_en: 'Savings',
      value: 24530.19
    },
    {
      name: 'air_loss',
      unit_de: 'm³/h',
      unit_en: 'm³/h',
      label_de: 'Druckluftverlust',
      label_en: 'loss of compressed air',
      value: 30.93
    }
  ]
};

export const INTRO_SUMMARY_PRESSURE = {
  id: 2,
  type: 'PRESSURE',
  title: 'Druckluft 4.0',
  data: [
    {
      name: 'v_stream',
      unit_de: 'm³/min',
      unit_en: 'm³/min',
      label_de: 'Volumenstrom',
      label_en: 'Flow',
      value: 12.5
    },

    {
      name: 'pressure',
      unit_de: 'bar',
      unit_en: 'bar',
      label_de: 'Druck',
      label_en: 'Pressure',
      value: 6.5
    },
    {
      name: 'dew',
      unit_de: '°C',
      unit_en: '°C',
      label_de: 'Drucktaupunkt',
      label_en: 'pressure dew point',
      value: 3.8
    },

    {
      name: 'specific_performance',
      unit_de: 'kW (m³/min)',
      unit_en: 'kW (m³/min)',
      label_de: 'Spezifische Leistung',
      label_en: 'Specific Performance',
      value: 6.72
    },
    {
      name: 'comp_temp',
      unit_de: '°C',
      unit_en: '°C',
      label_de: 'Verdichter-Temp.',
      label_en: 'Compression Temp.',
      value: 92
    },
    {
      name: 'next_maintenance',
      unit_de: 'h',
      unit_en: 'h',
      label_de: 'Nächste Wartung',
      label_en: 'Next Maintenance',
      value: 480
    }
  ]
};
