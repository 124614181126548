import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FadeIn } from '@looxr/animations';
import { Box, Button } from '@looxr/components';

const StyledIconButton = styled(Button)`
  &:hover {
    background-color: ${(props) => props.theme.colors.purple};

    & svg path {
      fill: ${(props) => props.theme.colors.white};
    }
  }

  span {
    position: relative;
    top: 2px;
  }
`;

function SummaryButtons({ visible, onEditClick, onDeleteClick, hasAccess }) {
  return (
    <FadeIn visible={visible} duration="0.8s">
      <Box
        display="flex"
        direction="column"
        justify="center"
        position="absolute"
        height="95px"
        top="0"
        right={2}
      >
        {hasAccess && (
          <StyledIconButton
            width={38}
            minWidth={38}
            height={38}
            background="white"
            borderRadius="50%"
            boxShadow="6px 6px 5px rgba(0, 0, 0, 0.7)"
            noPadding
            icon={<FontAwesomeIcon icon={faPen} size="1x" />}
            iconColor="purple"
            onClick={onEditClick}
          />
        )}

        <Box marginTop={1}>
          <StyledIconButton
            width={38}
            minWidth={38}
            height={38}
            background="white"
            borderRadius="50%"
            boxShadow="6px 6px 5px rgba(0, 0, 0, 0.7)"
            noPadding
            icon={<FontAwesomeIcon icon={faTrashAlt} size="1x" />}
            iconColor="purple"
            onClick={onDeleteClick}
          />
        </Box>
      </Box>
    </FadeIn>
  );
}

SummaryButtons.propTypes = {
  visible: PropTypes.bool,
  hasAccess: PropTypes.bool,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func
};

SummaryButtons.defaultProps = {
  visible: false,
  hasAccess: false,
  onEditClick: () => {},
  onDeleteClick: () => {}
};

export default SummaryButtons;
