import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from '@looxr/components';
import { BOX_TYPES } from '../constants';
import LeakSummaryEdit from './LeakSummaryEdit';
import PressureSummaryEdit from './PressureSummaryEdit';

function SummaryEditModal({ isVisible, onClose, blockScroll, boxConfig }) {
  return (
    <Modal
      show={isVisible}
      onClose={onClose}
      closeOnBackdrop={false}
      closeOnEsc={true}
      showModalCloseIcon={true}
      blockScroll={blockScroll}
    >
      {boxConfig.type === BOX_TYPES.leak && (
        <LeakSummaryEdit onSave={onClose} boxConfig={boxConfig} />
      )}
      {boxConfig.type === BOX_TYPES.pressure && (
        <PressureSummaryEdit onSave={onClose} boxConfig={boxConfig} />
      )}
    </Modal>
  );
}

SummaryEditModal.propTypes = {
  boxConfig: PropTypes.any.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  blockScroll: PropTypes.bool
};

SummaryEditModal.defaultProps = {
  onClose: () => {},
  blockScroll: true
};

export default SummaryEditModal;
