import React, { useState } from 'react';
import {
  Box,
  HelpModalTree,
  LanguageSelect,
  Navigation,
  NavigationItem,
  NavigationLink
} from '@looxr/components';
import { Collections, LOOXR_FIREBASE_ID } from '@looxr/constants';
import { Translate, useLanguage, useTranslation } from '@looxr/utils';
import { AppStateService, AuthService, FirebaseService } from '../services';
import SummaryCreateModal from './SummaryCreateModal';

function Header() {
  const tn = useTranslation();
  const language = useLanguage();

  const [helpModalVisible, setHelpModalVisible] = useState(false);
  const [showSummaryCreate, setShowSummaryCreate] = useState(false);

  const changeLocale = (locale) => {
    Translate.changeLanguage(locale);

    const userDoc = AppStateService.user;
    const userData = { ...userDoc };

    delete userData.id;
    delete userData.ref;

    // Update User
    userData.language = locale;
    userDoc.language = locale;

    AppStateService.setUser(userDoc);
    FirebaseService.upsertDoc(userDoc.id, userData, Collections.user);
  };

  const doLogout = () => {
    FirebaseService.logout();
    // Triggers a redirect
    AuthService.logout();
  };

  return (
    <>
      <Navigation>
        <Box display="flex" alignItems="center" justify="flex-start">
          <NavigationLink
            id="navi-add-summary"
            color="green"
            onClick={() => setShowSummaryCreate(true)}
            navLink={false}
            align="left"
          >
            {tn('landing.navi.add')}
            {/* Kachel hinzufügen */}
          </NavigationLink>
        </Box>

        <Box display="flex" alignItems="center" justify="flex-end">
          <NavigationItem>
            <LanguageSelect currentLocale={language} onChange={(locale) => changeLocale(locale)} />
          </NavigationItem>
          <NavigationLink onClick={() => setHelpModalVisible(!helpModalVisible)} navLink={false}>
            {tn('general.help')}
          </NavigationLink>
          <NavigationLink navLink={false} onClick={() => doLogout()}>
            {tn('general.logout')}
          </NavigationLink>
        </Box>
      </Navigation>
      <HelpModalTree
        isVisible={helpModalVisible}
        authCustomer={AppStateService.customer}
        looxrID={LOOXR_FIREBASE_ID}
        onClose={() => setHelpModalVisible(!helpModalVisible)}
        blockScroll={false}
      />
      {showSummaryCreate && (
        <SummaryCreateModal
          isVisible={showSummaryCreate}
          onClose={() => setShowSummaryCreate(false)}
          blockScroll={false}
        />
      )}
    </>
  );
}

export default Header;
