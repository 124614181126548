/* eslint-disable no-promise-executor-return */
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import firebase from 'firebase/app';
import { Collections, FirebaseConfig } from '@looxr/constants';

const FirebaseService = {
  ref: null,

  /**
   * Create a firebase timestamp
   */
  getTimestamp(srcDate) {
    const fromDate = srcDate !== undefined ? srcDate : new Date();
    return firebase.firestore.Timestamp.fromDate(fromDate);
  },

  async getImageDownloadUrl(uri) {
    const storage = firebase.storage();
    const storageRef = storage.ref();
    return storageRef.child(uri).getDownloadURL();
  },

  /**
   * Init Firebase
   */
  async init() {
    if (this.ref === null) {
      FirebaseService.ref = firebase.initializeApp(FirebaseConfig);
    }

    return true;
  },

  /**
   * Sign in with custom token
   */
  async signInWithToken(token) {
    return new Promise((resolve, reject) => {
      return this.ref
        .auth()
        .signInWithCustomToken(token)
        .then((user) => resolve(user))
        .catch((err) => reject(err));
    });
  },

  logout() {
    return new Promise((resolve, reject) => {
      return this.ref
        .auth()
        .signOut()
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  },

  /**
   * Create / Update Document
   */
  async upsertDoc(id, data, collection) {
    try {
      let result;
      let resultDoc;
      if (id) {
        resultDoc = await this.ref.firestore().collection(collection).doc(id).set(data);
        result = true;
      } else {
        resultDoc = await this.ref.firestore().collection(collection).add(data);

        result = resultDoc.id;
      }

      return result;
    } catch (error) {
      console.log(`Error writing ${collection} with data - [${id}]`, data);
      console.log(error);
      return false;
    }
  },

  /**
   * Delete summary box
   * @param {*} id
   */
  async deleteSummaryBox(id) {
    return FirebaseService.ref.firestore().collection(Collections.summaryBox).doc(id).delete();
  },

  /**
   * Get single user by uid
   */
  async getUser(uid) {
    return FirebaseService.ref.firestore().collection(Collections.user).doc(uid).get();
  },

  /**
   * Get single user query by uid
   */
  getUserQuery(uid) {
    return FirebaseService.ref.firestore().collection(Collections.user).doc(uid);
  },

  /**
   * Get all customer documents
   */
  async getCustomers() {
    return FirebaseService.ref.firestore().collection(Collections.customer).get();
  },

  /**
   * Update user profile data
   */
  async updateProfileData(id, data) {
    try {
      const result = await this.ref.firestore().collection(Collections.user).doc(id).update(data);

      return result;
    } catch (error) {
      console.log(`Error updating profile of user - [${id}]`, data);
      console.log(error);
      return false;
    }
  }
};

export default FirebaseService;
