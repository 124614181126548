/* eslint-disable no-nested-ternary */
export const REACT_SELECT_STYLES = {
  container: (provided) => ({
    ...provided,
    width: '100%'
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '14px'
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontSize: '14px'
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14px'
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    color: state.isDisabled
      ? '#c6c6c6'
      : state.isSelected
      ? '#fff'
      : state.isFocused
      ? '#fff'
      : '#000'
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '14px'
  })
};

export const REACT_SELECT_THEME = (theme) => ({
  ...theme,
  borderRadius: 4,
  colors: {
    ...theme.colors,
    primary: '#841d64',
    primary75: '#841d64',
    primary50: '#841d64',
    primary25: '#841d64'
  }
});
