import { getBoxTypeOptions, getLeakFieldOptions } from '../constants';

function useFormOptions() {
  const translatedOptions = [];

  // mapping name to the option constant
  const formOptionsMap = {
    boxTypes: {
      options: getBoxTypeOptions(),
      sort: false
    },
    leakFields: {
      options: getLeakFieldOptions(),
      sort: true
    }
  };

  const availableOptions = Object.keys(formOptionsMap);

  availableOptions.map((optionName) => {
    const { options, sort } = formOptionsMap[optionName];
    const translatedOptionSet = options.map((option) => {
      const i18nOption = {};

      i18nOption.value = option.value;
      // i18nOption.label = intl.get(option.label.id);
      i18nOption.label = option.label;
      // i18nOption.labelShort = option.labelShort ? intl.get(option.labelShort.id) : null;

      return i18nOption;
    });

    // Sort option set by translated label name
    if (sort) {
      translatedOptionSet.sort((a, b) => (a.label > b.label ? 1 : -1));
    }

    translatedOptions[optionName] = translatedOptionSet;

    return optionName;
  });

  return translatedOptions;
}

export default useFormOptions;
