import styled, { keyframes } from 'styled-components/macro';

const FadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const FadeIn = styled.div`
  animation-name: ${FadeInAnimation};
  animation-duration: ${props => props.duration};
  animation-timing-function: ${props => props.timingFunction};
  animation-delay: ${props => props.delay};
  animation-iteration-count: ${props => props.iterationCount};
  animation-direction: ${props => props.direction};
  animation-fill-mode: ${props => props.fillMode};
  animation-play-state: ${props => props.playState};
  display: ${props => (props.visible ? 'block' : 'none')};
`;

FadeIn.defaultProps = {
  duration: '0.8s',
  timingFunction: 'ease',
  delay: '0s',
  iterationCount: '1',
  direction: 'normal',
  fillMode: 'both',
  playState: 'running',
  visible: 'none'
};

export default FadeIn;
