import { AUTH_API_URL } from '@looxr/constants';

const PRESSURE_SIGNAL_PART = '/api/landing/pressure';
const ONELOGIN_PART = '/api/onelogin/user';
const FILES_PART = '/api/files';

const ApiService = {
  /**
   * Change Password of current session user
   * @return {object}
   */
  changePassword: async (newPassword) => {
    const url = `${AUTH_API_URL}${ONELOGIN_PART}/update-password`;
    const body = {
      password: newPassword
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      if (response.ok) {
        const jsonResponse = await response.json();

        return jsonResponse;
      }

      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  /**
   * Update user in pressure portal
   *
   * @param string id
   * @param object doc
   * @return object
   */
  getSignalValues: async (summaryBoxData) => {
    const url = new URL(`${AUTH_API_URL}${PRESSURE_SIGNAL_PART}/values`);
    const pressureSignalIds = summaryBoxData.signals;

    url.searchParams.append('machine', summaryBoxData.machineID);

    // @TODO use user language or AppState value
    url.searchParams.append('culture', 'de');

    for (let i = 0; i < pressureSignalIds.length; i += 1) {
      if (pressureSignalIds[i]) {
        url.searchParams.append('signals', pressureSignalIds[i]);
      }
    }

    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        return jsonResponse;
      }

      return false;
    } catch (e) {
      console.log('Error getting signal values', e);
      return false;
    }
  },

  /**
   * Update user in pressure portal
   *
   * @param string id
   * @param object doc
   * @return object
   */
  getMachines: async () => {
    const url = `${AUTH_API_URL}${PRESSURE_SIGNAL_PART}/machines`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        return jsonResponse;
      }

      return false;
    } catch (e) {
      console.log('Error gettting machine list for user', e);
      return false;
    }
  },

  /**
   * Update user in pressure portal
   *
   * @param string id
   * @param object doc
   * @return object
   */
  getSignals: async (machineID) => {
    const url = `${AUTH_API_URL}${PRESSURE_SIGNAL_PART}/signals?machine=${machineID}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        return jsonResponse;
      }

      return false;
    } catch (e) {
      console.log('Error gettting signal list for machine for user', e);
      return false;
    }
  },

  sendLicenseMail: async (sendToMail) => {
    const body = {
      sendToMail
    };

    const url = `${AUTH_API_URL}${FILES_PART}/send-license-mail`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      if (response.ok) {
        return true;
      }

      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
};

export default ApiService;
