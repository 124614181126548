import { Collections } from '@looxr/constants';
import { AppStateService, FirebaseService } from '../services';

function useLoadSummaryBoxes() {
  const getSummaryBoxesForUser = async () => {
    try {
      const query = getSummaryBoxesForUserQuery();
      const snapshot = await query.get();
      const data = [];

      snapshot.forEach((doc) => {
        if (doc.exists) {
          const docData = doc.data();
          data.push({
            id: doc.id,
            ref: doc.ref,
            ...docData
          });
        }
      });

      return data;
    } catch (error) {
      console.log('Error getting summary box data');
      console.log(error);
      return [];
    }
  };

  const getSummaryBoxesForUserQuery = () => {
    const currentUser = AppStateService.user;
    return FirebaseService.ref
      .firestore()
      .collection(Collections.summaryBox)
      .where('user', '==', currentUser.ref);
  };

  return {
    getSummaryBoxesForUser,
    getSummaryBoxesForUserQuery
  };
}

export default useLoadSummaryBoxes;
