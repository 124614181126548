import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { Banner, Box, Button } from '@looxr/components';
import { useLanguage, useTranslation } from '@looxr/utils';
import { BOX_TYPES } from '../constants';
import SummaryContentBlock from './SummaryContentBlock';

const StyledSummaryBox = styled(Box)`
  margin-bottom: 1.875rem;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    margin: 1rem 1.875rem;
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    width: 41%;
    max-width: 500px;
  }
`;

function DummyBox({ id, label, onClick, data }) {
  const language = useLanguage();
  const tn = useTranslation();

  return (
    <StyledSummaryBox id={id} background="white" paddingBottom={8}>
      <Box position="relative">
        <Banner subtext={data.title} inBox type={data.type} />
      </Box>
      <Box minHeight={180} height="auto" display="flex" wrap="wrap" paddingX={2} paddingY={6}>
        {data.data.map((value) => (
          <SummaryContentBlock
            key={value.name}
            type={data.type}
            label={language === 'de' ? value.label_de : value.label_en}
            value={value.value}
            unit={language === 'de' ? value.unit_de : value.unit_en}
            digits={value.digits}
          />
        ))}
      </Box>
      <Box display="flex" justify="center" alignItems="center">
        <Box>
          <Button
            background={data.type === BOX_TYPES.leak ? 'purple' : 'blue'}
            text={
              // eslint-disable-next-line no-nested-ternary
              label !== ''
                ? label
                : data.type === BOX_TYPES.leak
                ? tn('landing.summarybox.buttons.leak')
                : tn('landing.summarybox.buttons.pressure')
            }
            minWidth={200}
            onClick={onClick}
          />
        </Box>
      </Box>
    </StyledSummaryBox>
  );
}

DummyBox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.any,
  label: PropTypes.string,
  onClick: PropTypes.func
};

DummyBox.defaultProps = {
  id: null,
  data: null,
  label: '',
  onClick: () => null
};

export default DummyBox;
