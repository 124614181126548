import { Translate } from '@looxr/utils';

export const BOX_TYPES = {
  leak: 'LEAK',
  pressure: 'PRESSURE'
};

export const getLeakFields = () => {
  return {
    leaksTotal: {
      name: 'leaksTotal',
      label: Translate.tn('general.leaks'), // 'Leckagen',
      unit: '',
      digits: 0
    },
    repairedLeaksTotal: {
      name: 'repairedLeaksTotal',
      label: Translate.tn('general.leaksRepaired'), // 'Leckagen (repariert)',
      unit: '',
      digits: 0
    },
    airLoss: {
      name: 'airLoss',
      label: Translate.tn('general.airLoss'), // 'Druckluftverlust',
      unit: 'm³ / h',
      digits: 2
    },
    energyConsumption: {
      name: 'energyConsumption',
      label: Translate.tn('general.energyConsumption'), // 'Energieverbrauch',
      unit: Translate.tn('general.kwhPerYear'), // 'kWh / Jahr',
      digits: 2
    },
    energyCosts: {
      name: 'energyCosts',
      label: Translate.tn('general.energyCosts'), // 'Energiekosten',
      unit: Translate.tn('general.euroPerYear'), // '€ / Jahr',
      digits: 2
    },
    emission: {
      name: 'emission',
      label: Translate.tn('general.emission'), // 'CO₂-Emission',
      unit: Translate.tn('general.kgPerYear'), // 'kg / Jahr',
      digits: 2
    },
    energyCostsSaving: {
      name: 'energyCostsSaving',
      label: Translate.tn('general.savings'), // 'Einsparung',
      unit: Translate.tn('general.euroPerYear'), // '€ / Jahr',
      digits: 2
    }
  };
};

export const getBoxTypeOptions = () => {
  return [
    { label: Translate.tn('landing.summarybox.types.leak'), value: BOX_TYPES.leak } // 'Leckage'
    // { label: Translate.tn('landing.summarybox.types.pressure'), value: BOX_TYPES.pressure } // Druckluft
  ];
};

export const getLeakFieldOptions = () => {
  const leakFields = getLeakFields();
  return [
    { label: leakFields.leaksTotal.label, value: leakFields.leaksTotal.name },
    { label: leakFields.repairedLeaksTotal.label, value: leakFields.repairedLeaksTotal.name },
    { label: leakFields.airLoss.label, value: leakFields.airLoss.name },
    { label: leakFields.energyConsumption.label, value: leakFields.energyConsumption.name },
    { label: leakFields.energyCosts.label, value: leakFields.energyCosts.name },
    { label: leakFields.emission.label, value: leakFields.emission.name },
    { label: leakFields.energyCostsSaving.label, value: leakFields.energyCostsSaving.name }
  ];
};
