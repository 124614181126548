import { Collections } from '@looxr/constants';
import { getLeakFields } from '../constants';
import { FirebaseService } from '../services';

function useLoadLeakSummary() {
  const getLeakLocationSummary = async (boxConfig, customerRef) => {
    try {
      const query = getLeakLocationSummaryQuery(customerRef);
      const snapshot = await query.get();

      if (snapshot.docs.length > 0) {
        const summaryDoc = snapshot.docs[0];
        const data = summaryDoc.data();
        return mapSummaryWithConfig(boxConfig, data);
      }

      return false;
    } catch (error) {
      console.log('Error getting leak values for summary box config');
      console.log(error);
      return false;
    }
  };

  const getLeakLocationSummaryQuery = (customerRef) => {
    return FirebaseService.ref
      .firestore()
      .collection(Collections.leakLocationSummary)
      .where('customer', '==', customerRef);
  };

  const mapSummaryWithConfig = (config, summary) => {
    const allLeakFields = getLeakFields();
    const mapped = [];
    const leakFields = config.fields;

    for (let i = 0; i < leakFields.length; i += 1) {
      const fieldName = leakFields[i];

      if (fieldName && summary[fieldName] !== undefined) {
        const config = allLeakFields[fieldName];

        mapped[i] = {
          name: fieldName,
          value: summary[fieldName],
          label: config.label,
          unit: config.unit,
          digits: config.digits
        };
      } else {
        mapped[i] = {
          name: `empty-${i}`,
          value: null,
          label: null,
          unit: null,
          digits: null
        };
      }
    }

    return mapped;
  };

  return {
    getLeakLocationSummaryQuery,
    getLeakLocationSummary,
    mapSummaryWithConfig
  };
}

export default useLoadLeakSummary;
