import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Box,
  Button,
  Select as LOOXRSelect,
  Label,
  LoadingBox,
  Modal,
  TextInput
} from '@looxr/components';
import { Collections } from '@looxr/constants';
import { useTranslation } from '@looxr/utils';
import { BOX_TYPES, REACT_SELECT_STYLES, REACT_SELECT_THEME } from '../constants';
import useFormOptions from '../hooks/useFormOptions';
import useLoadCustomers from '../hooks/useLoadCustomers';
import { ApiService, AppStateService, FirebaseService } from '../services';

function SummaryCreateModal({ isVisible, onClose, blockScroll }) {
  const tn = useTranslation();
  const formOptions = useFormOptions();
  const { getCustomers } = useLoadCustomers();

  const [loadingOptions, setLoadingOptions] = useState(false);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [loadingMachines, setLoadingMachines] = useState(false);

  // general summary box data
  const [name, setName] = useState('');
  const [type, setType] = useState('');

  // leak type relevant data
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);

  // pressure type relevant data
  const [machineID, setMachineID] = useState('');
  const [pressureMachineOptions, setPressureMachineOptions] = useState([]);
  const [pressureSignalOptions, setPressureSignalOptions] = useState([]);

  // signal or leak field options
  const [boxOptions, setBoxOptions] = useState([
    null, // 1
    null, // 2
    null, // 3
    null, // 4
    null, // 5
    null // 6
  ]);

  const [valid, setValid] = useState(false);

  // Effect to keep valid state up to date
  useEffect(() => {
    setValid(false);
    if (name && name.length <= 40 && type) {
      const hasOneOption = boxOptions.filter((option) => option !== null).length > 0;

      if (type === BOX_TYPES.leak && selectedCustomer && selectedCustomer.id && hasOneOption) {
        setValid(true);
      }

      if (type === BOX_TYPES.pressure && machineID && hasOneOption) {
        setValid(true);
      }
    }
  }, [name, type, selectedCustomer, machineID, boxOptions]);

  // Effect to load correct options for box type
  useEffect(() => {
    const load = async () => {
      if (type) {
        setCustomerOptions([]);
        setSelectedCustomer(null);
        setMachineID('');

        if (type === BOX_TYPES.leak) {
          setLoadingCustomers(true);
          const customerDocs = await getCustomers();

          const customerDocOptions = customerDocs.map((doc) => {
            return {
              label: doc.name1,
              value: doc.id
            };
          });

          // Sort Alphabetically
          customerDocOptions.sort((a, b) => {
            if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
            if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
            return 0;
          });

          setCustomers(customerDocs);
          setCustomerOptions(customerDocOptions);
          setLoadingCustomers(false);
        }

        if (type === BOX_TYPES.pressure) {
          setLoadingMachines(true);
          const machineResult = await ApiService.getMachines();

          if (machineResult) {
            const machineOptions = machineResult.map((machine) => {
              return {
                label: machine.name,
                value: `${machine.id}` // secure the value to be a string
              };
            });

            setPressureMachineOptions(machineOptions);
            setLoadingMachines(false);
          }
        }
      }
    };

    load();
  }, [type]);

  // Effect to load signals based on machine id
  useEffect(() => {
    const load = async () => {
      if (machineID) {
        setLoadingOptions(true);
        // Reset Options and selected signals
        setPressureSignalOptions([]);
        setBoxOptions([null, null, null, null, null, null]);
        const signalResult = await ApiService.getSignals(machineID);

        if (signalResult) {
          const signalOptions = signalResult.map((signal) => {
            return {
              label: signal.label,
              value: `${signal.id}` // secure the value to be a string
            };
          });
          setPressureSignalOptions(signalOptions);
          setLoadingOptions(false);
        }
      }
    };

    load();
  }, [machineID]);

  const updateSelectedCustomer = (id) => {
    const selected = customers.find((c) => c.id === id);
    setSelectedCustomer(selected);
  };

  const updateBoxOptions = (index, option) => {
    const update = [...boxOptions];
    // third party select needs whole option object to map
    update[index] = option;

    setBoxOptions(update);
  };

  const isOptionDisabled = (index, selectOption) => {
    const disabled =
      boxOptions.filter((option) => {
        if (option && option.value === selectOption.value) {
          return option;
        }

        return null;
      }).length > 0;

    return disabled;
  };

  const saveSummary = async () => {
    if (valid) {
      try {
        const summaryBox = { name, type };

        summaryBox.user = AppStateService.user.ref;

        if (type === BOX_TYPES.leak) {
          summaryBox.customer = selectedCustomer.ref;
          summaryBox.fields = boxOptions.map((option) => (option !== null ? option.value : null));
        }

        if (type === BOX_TYPES.pressure) {
          const machineOption = pressureMachineOptions.find((option) => option.value === machineID);

          summaryBox.machineID = machineID;
          summaryBox.machineName = machineOption.label;
          summaryBox.signals = boxOptions.map((option) => (option !== null ? option.value : null));
        }

        const result = await FirebaseService.upsertDoc(null, summaryBox, Collections.summaryBox);

        if (result) {
          onClose();
        } else {
          // @TODO show error
        }
      } catch (e) {
        console.log(e);
        console.log('Error creating summary box');
      }
    }
  };

  const getTypeOptions = () => {
    let options = formOptions.boxTypes;

    if (!AppStateService.user.logins.leak) {
      options = options.filter((o) => o.value !== BOX_TYPES.leak);
    }

    if (!AppStateService.user.logins.pressure) {
      options = options.filter((o) => o.value !== BOX_TYPES.pressure);
    }

    return options;
  };

  return (
    <Modal
      show={isVisible}
      onClose={onClose}
      closeOnBackdrop={false}
      closeOnEsc={true}
      showModalCloseIcon={true}
      blockScroll={blockScroll}
    >
      <Box
        display="flex"
        alignItems="left"
        justify="flex-start"
        paddingX={5}
        paddingBottom={5}
        direction="column"
        minHeight="380px"
      >
        <Box>
          <Label htmlFor="name">
            {tn('landing.summaryboxform.name')}{' '}
            <small>
              ({tn('landing.summaryboxform.maxNameCharacters', { characters: name.length })})
            </small>
            {/* Name <small>({name.length} of max. 40 Characters)</small> */}
          </Label>
          <TextInput
            id="name"
            name="name"
            placeholder={tn('landing.summaryboxform.namePlaceholder')} //  "Bitte Name eingeben"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
        </Box>
        <Box marginTop={4}>
          <Label htmlFor="type">
            {tn('landing.summaryboxform.type')}
            {/* Typ */}
          </Label>
          <LOOXRSelect
            id="type"
            name="type"
            placeholder={tn('general.choose')} // "Bitte wählen"
            onChange={(e) => setType(e.value)}
            value={type || ''}
            options={getTypeOptions()}
            size="sm"
          />
        </Box>
        {type === BOX_TYPES.leak && (
          <Box marginTop={4}>
            <Label htmlFor="location">
              {tn('landing.summaryboxform.location')}
              {/* Standort */}
            </Label>
            <LoadingBox loading={loadingCustomers}>
              <LOOXRSelect
                id="location"
                name="location"
                placeholder={tn('general.choose')} // "Bitte wählen"
                onChange={(e) => updateSelectedCustomer(e.value)}
                value={selectedCustomer ? selectedCustomer.id : ''}
                options={customerOptions}
                size="sm"
              />
            </LoadingBox>
          </Box>
        )}

        {type === BOX_TYPES.pressure && (
          <Box marginTop={4}>
            <Label htmlFor="location">
              {tn('landing.summaryboxform.machine')}
              {/* Maschine */}
            </Label>
            <LoadingBox loading={loadingMachines}>
              <LOOXRSelect
                id="machine"
                name="machine"
                placeholder={tn('general.choose')} // "Bitte wählen"
                onChange={(e) => setMachineID(e.value)}
                value={machineID}
                options={pressureMachineOptions}
                size="sm"
              />
            </LoadingBox>
          </Box>
        )}

        {(selectedCustomer || machineID) && (
          <Box marginTop={4}>
            <Label htmlFor="location">
              {tn('landing.summaryboxform.displayValues')}
              {/* Display Werte */}
            </Label>
            <LoadingBox loading={loadingOptions}>
              <Box display="flex" wrap="wrap" direction="row" justify="space-between">
                {[0, 1, 2, 3, 4, 5].map((i) => (
                  <Box display="flex" width="30%" key={i} marginBottom={4}>
                    <Select
                      menuPosition="fixed"
                      styles={REACT_SELECT_STYLES}
                      theme={REACT_SELECT_THEME}
                      value={boxOptions[i]}
                      onChange={(o) => updateBoxOptions(i, o)}
                      options={
                        type === BOX_TYPES.leak ? formOptions.leakFields : pressureSignalOptions
                      }
                      isClearable={true}
                      noOptionsMessage={tn('general.noResult')} // "Kein Ergebnis"
                      placeholder={tn('general.choose')} // "Bitte wählen"
                      isOptionDisabled={(o) => isOptionDisabled(i, o)}
                    />
                  </Box>
                ))}
              </Box>
            </LoadingBox>
          </Box>
        )}
      </Box>
      <Box marginTop={4}>
        <Button
          onClick={saveSummary}
          text={tn('general.save')} // "Speichern"
          disabled={!valid}
        />
      </Box>
    </Modal>
  );
}

SummaryCreateModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  blockScroll: PropTypes.bool
};

SummaryCreateModal.defaultProps = {
  onClose: () => {},
  blockScroll: true
};

export default SummaryCreateModal;
