import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import Box from './Box';
import Button from './Button';
import Text from './Text';

const StyledAlertWrapper = styled.div`
  display: block;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
`;

const StyledBackDrop = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  background-color: ${(props) => props.theme.colors.grey2};
  opacity: 0.9;
`;

function Alert({ size, show, title, message, btnText, btnAction, btnColor }) {
  if (!show) {
    return null;
  }

  return (
    <StyledAlertWrapper>
      <Box
        zIndex={1001}
        position="fixed"
        height="100%"
        width="100%"
        left="0"
        display="flex"
        justify="center"
        alignItems="center"
      >
        <Box width={size} background="white" padding={5}>
          <Text size="lg" color="grey" bold>
            {title}
          </Text>
          <Box marginY={5}>
            <Text size="sm">{message}</Text>
          </Box>
          <Box display="flex" justify="center" alignItems="center" marginTop={10}>
            <Box>
              <Button text={btnText} onClick={btnAction} background={btnColor} />
            </Box>
          </Box>
        </Box>
      </Box>
      <StyledBackDrop show={true} />
    </StyledAlertWrapper>
  );
}

Alert.propTypes = {
  size: PropTypes.number,
  show: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  btnText: PropTypes.string,
  btnColor: PropTypes.string,
  btnAction: PropTypes.func
};

Alert.defaultProps = {
  size: 400,
  show: false,
  title: '',
  message: '',
  btnText: 'Ok',
  btnColor: 'red',
  btnAction: null
};

export default Alert;
