import { Translate } from '@looxr/utils';

export const INTRO_STYLES = {
  buttonClose: {
    display: 'none'
  },
  buttonNext: {
    backgroundColor: '#46b9a0',
    borderRadius: '36px',
    color: '#fff',
    padding: '12px 25px',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '600'
  },
  buttonBack: {
    color: '#841d64',
    fontSize: '14px',
    fontWeight: '500'
  },
  buttonSkip: {
    color: 'rgba(29, 29, 27, 0.55)',
    fontSize: '14px',
    fontWeight: '500'
  },
  tooltipTitle: {
    fontSize: '14.5px',
    fontWeight: '700',
    color: '#000'
  },
  tooltipContent: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#1D1D1B'
  }
};

export const getIntroSteps = () => {
  return [
    {
      target: '#navi-add-summary',
      title: Translate.tn('landing.intro.first.title'), // 'Hinzufügen',
      content: Translate.tn('landing.intro.first.text'), // 'Fügen Sie neue Kacheln hinzu und erstellen Sie so Ihre Individuelle Übersicht.',
      disableBeacon: true
    },
    {
      target: '#intro-summary-leak',
      title: Translate.tn('landing.intro.second.title'), // 'Leckage Zusammenfassung',
      content: Translate.tn('landing.intro.second.text'), // 'Ihre Übersicht der aktuellen Leckagewerte für Ihren Standort. Mit einem Klick gelangen Sie ins Leckage-Portal für detaillierte Informationen.',
      disableBeacon: true
    },
    {
      target: '#intro-summary-pressure',
      title: Translate.tn('landing.intro.third.title'), // 'Druckluft Zusammenfassung',
      content: Translate.tn('landing.intro.third.text'), // 'Ihre Übersicht der aktuellen Druckluftwerte für Ihre Maschine. Mit einem Klick gelangen Sie ins Druckluft-Portal für detaillierte Informationen.',
      disableBeacon: true
    }
  ];
};
